import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {Problem} from './entities/problem';
import {ProblemCollectionResponse} from './entities/problem-collection-response';
import {ProblemPaginationResponse} from './entities/problem-pagination-response';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {GenericPaginationResponse} from '../base/entities/generic-pagination-response';

@Injectable({
  providedIn: 'root'
})
export class ProblemListRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {
  }

  query(query: string): Observable<Problem[]> {
    return this.api.get<ProblemCollectionResponse>('/problemlist/search/', {query})
      .pipe(map(response => response.items));
  }

  loadPatientProblemList(patientId: number): Observable<Problem[]> {
    return this.api.get<ProblemCollectionResponse>('/problemlist/patient/' + patientId)
      .pipe(map(response => response.items));
  }

  addProblemToPatient(patientId: number, problem: Problem) {
    return this.api.post<ItemCreatedResponse>('/problemlist/patient/' + patientId, problem)
      .pipe(map(response => response.value));
  }

  removeProblemFromPatient(patientId: number, problemId: number) {
    return this.api.delete<ItemDeletedResponse>('/problemlist/patient/' + patientId + '/' + problemId)
      .pipe(map(response => response));
  }

  createProblemItem(problem: Problem): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/problemlist', problem)
      .pipe(map(response => response.value));
  }

  adminPaginate(type: string, pageNumber: number, pageSize: number, filter: string): Observable<ProblemPaginationResponse> {
    return this.adminApi.get<GenericPaginationResponse<ProblemPaginationResponse>>('/problemlist/' + type, {pageNumber: pageNumber, pageSize: pageSize, filter: filter})
      .pipe(map(response => response.value));
  }

  adminCreateProblem(problem: Problem): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/problemlist', problem)
      .pipe(map(response => response.value));
  }

  adminUpdateProblem(id: number, problem: Problem): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/problemlist/' + id, problem)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDeleteProblem(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/problemlist/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

}
